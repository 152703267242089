
.time-container {
    display: flex;
    flex-direction: row;
}

.decrease-button {
    width: 40px;
    background-color: #1770B2;
    color: #FFFFFF;
    border-color: #1770B2;
    border-width: 2px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: transform 80ms ease-in;
}

.decrease-button:active {
    transform: scale(0.95);
    border: 2px solid #1770B2;
    outline: none;
    padding: 0;
}

.time-screen {
    border-width: 1px 0;
    border-style: solid;
    border-color: #cccccc;
}

.increase-button {
    width: 40px;
    background-color: #1770B2;
    color: #FFFFFF;
    border-color: #1770B2;
    border-width: 2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: transform 80ms ease-in;
}

.increase-button:active {
    transform: scale(0.95);
    border: 2px solid #1770B2;
    outline: none;
    padding: 0;
}

button:focus {
    outline: 0;
}