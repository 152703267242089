.table-main {
    width: 98%;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 20px;
}

.table-cell-name {
    width: 20%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-pNumber {
     width: 5%;
     border-width: 0.25px;
     border-style: solid;
     border-color: #cccccc;
 }

.table-cell-invoice {
    width: 32%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}


.table-cell-desc {
    width: 30%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}


.table-cell-unit {
    width: 5%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-icon {
    width: 4%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}
