.product-content {
    width: 100%;
    height: 100%;
    padding: 30px;
}

.product-link {
    text-decoration: none;
    color: #000000;
}

.product-utility {
    width: 100%;
    height: 100%;
    display: flex;
}

.left {
    height: 10px;
    width: 100%;
    justify-content: flex-start;
}

.right {
    height: 10px;
    width: 100%;
    justify-content: flex-end;
}

.filterItem-container {
    height: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.05);
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-left: 5px;
}


.filterItem-text {
    margin: 0;
    padding: 0;
}

@keyframes filterItem {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}