
.module-list-container {
    display: flex;
    margin-left: 20px;
}

.module-list-column {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.module-list-left {
    width: 360px;
}

.module-permissions h3 {
    margin-left: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.module-permission-title {
    display: flex;
    align-items: center;
}

.module-permission-title:hover {
    cursor: pointer;
}