
.list-item-container {
    display: flex;
    justify-content: space-between;
    width: 98%;
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-color: #cccccc;
    border-bottom-style: solid;
}

.list-item-container-noBorder {
    display: flex;
    justify-content: space-between;
    width: 98%;
    padding: 10px;
}

.list-item-container:hover {
    background-color: #cccccc;
    cursor: pointer;
}

.list-item-container-noBorder:hover {
    background-color: #cccccc;
    cursor: pointer;
}

.list-item-container p {
    margin: 0;
    font-size: 14px;
    width: 12%;
}

.list-item-container-noBorder p {
    margin: 0;
    font-size: 14px;
    width: 12%;
}

.list-item-center {
    display: flex;
    justify-content: center;
}