.filterMenu {
    width: 200px;
    height: 200px;
    position: absolute;
}

.filterMenu-container {
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #ffffff;
    border: 2px solid #1770B2;
    position: relative;
    margin-bottom: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2);
}


.filterMenu-notch {
    width: 20px;
    height: 20px;
    z-index: 11;
    border-top: 2px solid #1770B2;
    border-left: 2px solid #1770B2;
    position: absolute;
    top: -12px;
    left: 87px;
    transform: rotateZ(45deg);
    background-color: #ffffff;
}

.filterMenu-content {
    width: 100%;
    height: 100%;
    z-index: 12;
    background-color: #ffffff;
    margin-bottom: 5px;
}
