.information-container2 {
    display: flex;
    width: 100%;
    height: 35px;
}

.field-centering-left {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    font-size: 14px;
}

.field-centering-right {
    display: flex;
    justify-content: flex-start;
    width: 60%;
    margin-left: 10px;
    font-size: 14px;
}