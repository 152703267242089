.crm-email-container {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.crm-flexbox {
    display: flex;
}

.email-container {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 680px;
}

.attachment-container {
    width: 50%;
    padding: 20px;
    height: 680px;
}

.crm-send-row {
    width: 50%;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}

@media only screen and (max-width: 1600px) {
    .crm-flexbox {
        flex-direction: column;
    }
}