.module-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.module-display {
    width: 100%;
    margin-right: 20px;
}

.module-display-userHeader {
    width: 98%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-color: #cccccc;
    border-bottom-style: solid;
}

.module-display-userHeader p {
    margin: 0;
    font-weight: bold;
    font-size: 14px;
    width: 12%
}

.module-display li {
    list-style-type: none;
}

.module-display ul {
    margin-top: 0;
}

.module-price-display {
    width: 350px;
    height: 70vh;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #cccccc;
}

.module-price-display h4 {
    margin-left: 40%;
}



.list-item-center {
    display: flex;
    justify-content: center;
}