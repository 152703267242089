.settings-container {
    width: 100%;
    display: flex;
    padding: 20px;
}

.settings-navigation {
    width: 250px;
    height: 350px;
}

.settings-display {
    width: 100%;
    margin-left: 20px;
    overflow-y: auto;
    height: 80vh;
}

.settings-footer {
    width: auto;
    height: 5%;
    position: absolute;
    bottom: 10px;
    left: 310px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
}

