.dialog-information-container {
    display: flex;
    width: 100%;
    height: 150px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 30px;
}

.dialog-information-field-centering-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 10%;
    height: 100px;
    font-size: 14px;
    //margin-left: 30px;
}
.dialog-information-field-centering-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 30%;
    height: 100px;
    font-size: 13px;

}
.dialog-information-field-centering-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 64%;
    height: 100px;
    margin-right: 20px;
    font-size: 9px;
    overflow-y: scroll;
}
