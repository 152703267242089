

.bill-summary-container {
    width: 100%;
    margin-top: 50px;
}

.bill-summary-header {
    width: 100%;
    height:50px;
    background-color: #1770b2;
    text-align: left;
}

.bill-delivery-price {
    display: flex;
    justify-content: flex-end;
}

.row-container {
    display: flex;
    justify-content: space-between;
}

.attachments {
    width: 100%;
}

.summary-prices {
    margin-right: 50px;
    width: 200px;
    display: flex;
    justify-content: space-between;
    height: 30px;
}

.toPay {
    font-size: 18px;
    font-weight: 600;
}

p {
    font-size: 14px;
}

.header-txt {
    color: #ffffff;
    margin-left: 10px;
    font-size: 20px;
    padding-top: 15px;
}

.bill-delivery-item {
    display: flex;
    width: 230px;
    margin-right: 60px;
}

.delivery-left {
    width: 70%;
    text-align: right;
    padding-top: 10px;
}

.delivery-right {
    width: 30%;
    text-align: left;
}
