.we-container {
    width: 100%;
    height: 97%;
    padding: 20px;
}

.we-titlebar {
    width: 100%;
    height: 40px;
    background-color: #1770b2;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 10px;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.we-row-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
}

.we-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.we-task-todo-column-container {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.we-2 {
    width: 50%;
}

.we-3 {
    width: 33%;
}

.we-h2 {
    height: 15%
}

.we-h3 {
    height: 25%;
}

.we-h4 {
    height: 50%;
}

.we-brd {
    border-right: 1px solid #1770b2;
}

.we-item-delete {
    display: flex;
    justify-content: space-between;
    width: 325px;
}

.we-item-delete p {
    margin: 0;
    padding-top: 15px;
}


.we-center-item {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.we-center-left {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    font-size: 18px;
}

.we-center-right {
    display: flex;
    justify-content: flex-start;
    width: 49%;
    padding-left: 1%;
    font-size: 18px;
}

.wd-workday-info-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 600px;
    height: 500px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.wd-payable-hours {
    width: 400px;
    display: flex;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 5px;
}


.we-task-todo-column {
    width: 300px;
    display: flex;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.we-additional-info {
    width: 80%;
    display: flex;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.payable-start {
    display: flex;
    justify-content: flex-start;
    width: 40%;
    margin-left: 15px;
}

.payable-middle {
    display: flex;
    justify-content: center;
    width: 40%;
}

.payable-end {
    display: flex;
    justify-content: flex-end;
    width: 20%;
}



.we-task-header {
    width: 300px;
    border-bottom: 1px solid #1770b2;
    display: flex;
    height: 20px;
    margin-bottom: 20px;
}

.we-item-buttons {
    display: flex;
    justify-content: space-between;
    width: '100%';
    margin-bottom: 10px;
    font-size: 20px;
}

.we-button-row {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
