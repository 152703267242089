.userlist-container {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.user-utility {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.user-link {
    text-decoration: none;
    color: #000000;
}
