.listAreaItem {
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.checkBox {
    width: 5%;
}

.smallItem {
    width: 5%;
}

.mediumItem {
    width: 10%;
}

.largeItem {
    width: 20%
}

.fullWidth {
    width: 100%;
}

.item-icon {
    position: relative;
    bottom: 5px;
}

.list-checkbox {
    padding: 0;
    color: #1770B2;
}

.check-show {
    animation: slideIn 500ms ease-in;
}

@keyframes slideIn {
   from {
       transform: translateX(-20px);
       opacity: 0;
   }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}


@media only screen and (max-width: 1300px) {
    .smallItem {
        min-width: 120px;
    }
}
