.cws-container {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
}

.cws-checkbox {
    width: 20px;
    height: 20px;
    display: flex;
    border: 2px solid #1770B2;
    border-radius: 3px 0 0 3px;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.cws-checkbox-checked {
    border: 0;
    width: 28px;
    height: 28px;
    position: relative;
    right: 1px;
}

.cws-checkbox:hover {
    cursor: pointer;
}

.cws-checkbox-checked:hover {
    cursor: pointer;
}

.checkbox-btn {
    width: 26px;
    height: 24px;
    padding: 0;
    border: 0;
    background-color: #1770b2;
    color: #FFF;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-left: 1px solid #ccc;
    position: relative;
    right: 1px
}

.checkbox-btn:active {
    transform: scale(0.95);
}

.checkbox-btn-checked {
    right: 3px;
    margin-top: 2px;
}
