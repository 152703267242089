.details-content {
    width: 100%;
    overflow-y: auto;
}

.details-container {
    width: 80vw;
    margin: 3% auto 0 auto
}


.details-button-container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}


