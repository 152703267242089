.textfield-container {
    border: 1px solid #1770b2;
    border-radius: 4px;
    padding: 5px 10px;
    width: 90%;
    display: flex;
    height: 20px;

}

.textfield-select {
    cursor: pointer;
}

.textfield-select-input {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    padding-top: 2px;
}

.textfield-error {
    border: 1px solid #B22717;
}

input {
    font-size: 14px;
    width: 100%;
    border: 0;
}

input:focus {
    outline: none;
}
