.jm-list-container {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.jm-utility {
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: flex-end;
}

.jm-link {
    text-decoration: none;
    color: #000000;
}
