
.productrows-table {
    margin-top: 40px;
    width: 100%;
}

.productrows-table-head {
    background-color: #1770B2;
    color: #FFF;
    height: 60px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
}

.productrows-table-row {
    height: 60px;
    display: flex;
    font-size: 16px;
    font-weight: 400;
}

.productrows-table-cell-small {
    border-width: 0.5px;
    border-style: solid;
    border-color: #cccccc;
    width: 6.5%;
    height: 60px;
    text-align: center;
}

.productrows-text-small {
    padding-top: 20px;
}

.productrows-table-cell-large{
    border-width: 0.5px;
    border-style: solid;
    border-color: #cccccc;
    width: 25%;
    height: 60px;
}

.productrows-text-large{
    padding-top: 20px;
    margin-left: 10px;
}
