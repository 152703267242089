.ac-list {
    position: absolute;
    background-color: #ffffff;
    border: 0.5px solid #cccccc;
    z-index: 10;
}

.ac-list-item {
    display: flex;
    border-bottom: 0.5px solid #cccccc;
    width: 94%;
    padding: 10px
}

.ac-list-item:hover {
    cursor: pointer;
    background-color: #cccccc;
}