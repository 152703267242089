.customer-edit-container {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.wrapper {
    display: flex;
    padding: 0;
}

.row1 {
    height: 30%;
}

.row2 {
    height: 35%;
}

.row3 {
    height: 30%;
}

.customer-edit-title-bg {
    background-color: #1770b2;
    color: #FFFFFF;
    height: 40px;
    display: flex;
    padding-left: 15px;
    align-items: center;
    border-radius: 2px;
    margin: 20px 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.customer-edit-column {
    width: 33%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.middle-brd {
    border-left: 1px solid #1770b2;
    border-right: 1px solid #1770b2;
}

.customer-textfield {
    width: 100%;
    display: flex;
    padding: 0 5px;
}

.customer-tf-left {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.customer-tf-right {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.customer-edit-buttons {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 80px;
}

h4 {
    margin-top: 0;
    margin-bottom: 10px;
}

.menuItem:first-child:hover {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.menuItem:last-child:hover {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.menuItem:hover {
    background-color: #cccccc;
}
