.log-list-header {
    width: 100%;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #FFFFFF;
}
.log-list-header-small {
    width: 5%;
    margin: 0;
}

.log-list-header-nonCheckbox {
    width: 5%;
    margin: 0;
    color: #7d888d;
    font-size: 14px;
    text-align: center;
    padding-top: 15px;
}


.log-list-header-large {
    width: 16%;
    margin: 0;
    text-align: center;
    padding-top: 15px;
    color: #7d888d;
    font-size: 14px;
}

.log-list-header-date {
    width: 16%;
    margin: 0;
    text-align: center;
    padding-top: 15px;
    color: #7d888d;
    font-size: 14px;
}

.log-list-header-date:hover {
    cursor: pointer;
}

.log-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.log-list-item-container {
    width: 100%;
    display: flex;
}

.log-list-item-container:hover {
    background-color: #cccccc;
    cursor: pointer;
}

.log-list-item-container-inner {
    flex-grow: 1;
    display: flex;
}

.log-list-item-small {
    width: 5%;
    margin: 0;
}

.log-list-item-small-nonCheckbox {
    width: 5%;
    margin: 0;
    padding-top: 15px;
    text-align: center;
}

.log-list-item-large {
    width: 17%;
    margin: 0;
    text-align: center;
    padding-top: 15px;
    font-size: 13px;
}

.log-divider {
    width: 100%;
    background-color: #cccccc;
}
.stickyHead {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #ffffff;
}

.stickyDate {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #ffffff;
}

.stickyDate:hover {
    cursor: pointer;
}

.dataRow:hover {
    cursor: pointer;
}

