.utilityBar-container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    margin-top: 100px;
}

.utilityBar-searchBar {
    height: 40px;
    border-radius: 2px;
    width: 300px;
    display: flex;
}

.utilityBar-searchBar input {
    height: 35px;
    font-size: 18px;
    width: 100%;
    border: 0;
}

.utilityBar-searchBar input:focus {
    outline: none;
}

.utilityBar-icon {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
}

.utilityBar-children {
    width: 100%;
    margin-left: 20px;
    height: 100%;
}