.listAreaBody-container {
    width: 100%;
    height: 23px;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    justify-content: space-evenly;
    padding: 15px 0;
    border-bottom: 0.5px solid #1770B2;
}

.listAreaBody-container:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #1770B2;
    animation: listitem 0.2s linear;
    animation-fill-mode: both;
}

@keyframes listitem {
    100% {
        font-size: 16px;
    }
}