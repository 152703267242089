
.cl-container {
    height: 110px;
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hide {
    height: 0;
    width: 0;
    opacity: 0;
}

.medium {
    height: 160px;
    width: 160px;
}

.large {
    height: 200px;
    width: 200px;
}

.loader {
    height: 35%;
    animation: rotate 3s linear infinite;
    transform-origin: bottom center;
}

.cloud {
    display: inline-block;
    height: 30px;
    transform: scale(0);
    animation: grow 1.5s linear infinite;

}

.cloud:nth-child(2) {
    animation-delay: 0.75s;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes grow {
    50% {
        transform: scale(1);
    }

}
