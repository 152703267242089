
.window {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
}

.tutorial-container {
    position: absolute;
    text-align: center;
    top: 35%;
    left: 25%;
    width: 50vw;
    height: 200px;
    color: #FFFFFF;
    z-index: 1;
    border: 1px solid white;
}