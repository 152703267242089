.wl-select {
    width: 150px;
    height: 30px;
    border: 1px solid gray;
    padding: 5px 10px;
    margin-right: 10px;
    display: flex;

}

.wl-select:hover {
    cursor: pointer;
}

.wl-input {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wl-input-icon {
    height: 20px;
    margin-bottom: 5px;

}

.wl-placeholder {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6)
}

.wl-menu {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 10;
    background-color: #FFFFFF;
}

.wl-select-item {
    width: 95%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 5%;
}


.wl-select-item:hover {
    background-color: #cccccc;
    cursor:pointer;
}
