.recipientsMenu {
    width: 400px;
    height: 90px;
    position: absolute;
    overflow-x: scroll;
    overflow-y: scroll;
}

.recipientsMenu-container {
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #ffffff;
    border: 1px solid #bbddf6;
    position: relative;
    margin-bottom: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2);
}


.recipientsMenu-notch {
    width: 20px;
    height: 20px;
    z-index: 11;
    border-top: 1px solid #bbddf6;
    border-left: 1px solid #bbddf6;
    position: absolute;
    top: -12px;
    left: 87px;
    transform: rotateZ(45deg);
    background-color: #ffffff;
}

.recipientsMenu-content {
    width: 100%;
    height: 100%;
    z-index: 12;
    background-color: #ffffff;
    margin-top: 5px;
    margin-bottom: 2px;
    overflow-x: auto;
    overflow-y: auto;
}
