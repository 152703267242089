.additional-header {
    width: 100%;
    background-color: #1770B2;
    height: 50px;
    margin-top: 50px;
}

.additional-header-text {
    color: #ffffff;
    font-size: 20px;
    margin-left: 10px;
    padding-top: 15px;
}

.additional-container {
    display: flex;
    width: 100%;
    height: 350px
}

.additional-left {
    flex: 1;
    margin-top: 10px;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: #cccccc;
}

.additional-center {
    flex: 1;
    margin-top: 10px;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: #cccccc;
}

.additional-right {
    margin-top: 10px;
    flex: 1;
}

.additional-title {
    font-size: 14px;
    font-weight: bold;
    margin-left: 40%;
}

.additional-checkbox {
    margin-left: 35%
}