
.modulePrice-container {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 20px;
    margin-bottom: 5px;
}

.modulePrice-container p {
    margin: 0;
}