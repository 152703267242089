.wl-container {
    width: 100%;
    height: 95%;
    padding: 20px;
}

.wl-search {
    width: 100%;
    height: 70px;
    display: flex;
}
.wl-date-range {
    height: 42px;
    border-radius: 4px;
}

.wl-on-select-header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #1770b2;
}

.wl-on-select-row {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #1770b2;
    font-size: 14px;
    align-items: center;
}

.wl-list-header {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #1770b2;
    font-weight: bold;
}

.wl-list-body {
    width: 100%;
    height: 80vh;
    overflow-y: auto;
}

.wl-list-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 45px;
    border-bottom: 1px solid #1770b2;
    font-size: 14px;
}

.wl-left {
    width: 8%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.wl-header-right {
    width: 92%;
    display: flex;
    align-items: center;
}

.wl-right {
    width: 100%;
    display: flex;
    align-items: center;
}

.wl-list-item {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.small {
    width: 12.8%;
}

.warn {
    color: #FF5733;
}

.not-billable {
    color: #30bbdd;
}

.large {
    width: 18%;
}

.wl-link {
    width: 92%;
    text-decoration: none;
    color: #000000;
    display: flex;
    align-items: center;
}

.wl-list-row:hover {
    background-color: #1770b2;
    font-size: 15px;
    cursor: pointer;
}

.wl-list-row:hover .wl-right {
    color: #ffffff;
}

.wl-summary-item {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.small {
    width: 12.8%;
}

.warn {
    color: #FF5733;
}