.listAreaHeader-container {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #1770B2;
    margin-top: 30px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    justify-content: space-evenly;
}
