.tf-label-container {
    display: flex;
    width: 95%;
    margin-bottom: 3px;
}

.tf-label-left {
    width: 40%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    align-items: center;
}

.tf-label-right {
    width: 60%;
    height: 46px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    flex-direction: column;
}

.tf-error-text {
    margin-left: 3px;
    font-size: 12px;
    color: #B22717;
}

p {
    margin: 0;
    padding: 0;
}
