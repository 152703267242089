.task-search {
    height: 200px;
    width: 300px;
    overflow-y: auto;
}

.task-list-title {
    font-size: 14px;
    font-weight: bold;
}

.task-list {
    display: flex;
    flex-direction: column;
}

.task-list-item {
    margin-left: 10px;
    font-size: 13px;
}

.task-list-item:hover {
    background-color: #cccccc;
    cursor: pointer;
}

.task-list-item-container {
    display: flex;
    flex-direction: row;
}

.p-button {
    color: #1770B2;
    margin-bottom: 0;
    margin-left: 15px;
    transition: transform 80ms ease-in;
}

.p-button:hover {
    cursor: pointer;
}

.p-button:active {
    transform: scale(0.95);
}
