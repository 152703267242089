
.content {
    width: 100%;
    padding: 20px;
}

.disable-scroll {
    overflow: hidden;
}

.bill-utility {
    width: 100%;
    height: 100%;
    display: flex;
}

.left {
    height: 10px;
    width: 100%;
    justify-content: flex-start;
}

.right {
    height: 10px;
    width: 100%;
    justify-content: flex-end;
}

.link {
    text-decoration: none;
    text-decoration-color: #000000;
    color: #000000;
}

.filterItem-container {
    height: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.05);
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin-left: 5px;
}


.filterItem-text {
    margin: 0;
    padding: 0;
}

.bl-right {
    width: 100%;
    display: flex;
    align-items: center;
}

.billing-summary-item {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: right;
    padding: 0 20px;
}

.small {
    width: 12.8%;
}

.small-font {
    font-size: 8;
    color: rgba(89, 87, 87, 0.836);
    font-style: "italic";
}

.smaller-font {
    font-size: 6;
    color: rgba(89, 87, 87, 0.836);
    font-style: "italic";
}

.report-list-item-send-button {
    width: 30px;
    padding: 2px;
    
}