
/*Employee, template, task, product unit, bill*/
.table-cell-employee {
    width: 22%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-invoice {
    width: 22%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-task {
    width: 23%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-product {
    width: 23%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-unit {
    width: 5%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}

.table-cell-bill {
    width: 5%;
    border-width: 0.25px;
    border-style: solid;
    border-color: #cccccc;
}