.details-header {
    width: 100%;
    background-color: #1770B2;
    height: 50px;
}

.details-header-text {
    color: #ffffff;
    font-size: 20px;
    margin-left: 10px;
    padding-top: 15px;
}

.information-container {
    display: flex;
    width: 100%;;
}

.information-left {
    flex: 1;
    margin-top: 10px;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: #cccccc;
}

.information-center {
    flex: 1;
    margin-top: 10px;
    border-right: 1px;
    border-right-style: solid;
    border-right-color: #cccccc;
}

.information-right {
    margin-top: 10px;
    flex: 1;
}