.ac-container {
    width: 150px;
    height: 30px;
    border: 1px solid gray;
    padding: 5px 10px;
    margin-right: 10px;

}
.ac-input {
    font-size: 14px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.ac-menu {
    z-index: 10;
    border-radius: 2px;
    position: absolute;
    background-color: #FFF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.ac-listItem {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
}

.ac-listItem p {
    margin: 5px 10px;
}

.ac-listItem:hover {
    background-color: #cccccc;
    cursor: pointer;
}


