.action-menu {
    position: absolute;
    background-color: #FFFFFF;
    z-index: 50;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
}

.action-menu-horizontal {
    width: 400px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    transition: all 500ms ease-in-out;
    transform: translateX(0);
}

.right-active {
    transform: translateX(-200px);
}

.horizontal-left {
    width: 200px;
}

.horizontal-right {
    width: 200px;
}


.action-menu-item {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    height: 40px;
    align-items: center;
}

.action-menu-item:hover {
    cursor: pointer;
    background-color: #cccccc;
}

.back {
    justify-content: flex-start;
}

.back p {
    margin-left: 20px;
}
